<template>
	<div class="content" v-if="isShow && recommends.length > 0">
		<van-list v-model="loading" :finished="finished" finished-text="到底啦〜想不到你看到了这里^_^" @load="getMore">
			<ul class="content-list">
				<goods-item :listType="listType" :goods="item" :goodsTab="goodsTab" v-for="(item, index) in recommends"
					:key="index"></goods-item>
			</ul>
		</van-list>
	</div>
	<no-data v-else></no-data>
</template>

<script>
	import GoodsItem from "./GoodsItem";
	export default {
		components: {
			GoodsItem,
		},
		props: {
			listType: {
				type: String,
				default () {
					return "";
				},
			},
			goodsTab: {
				type: Number,
				default () {
					return 0;
				},
			},
			recommends: {
				type: Array,
				default () {
					return [];
				},
			},
			isShow: {
				type: Boolean,
				default () {
					return false;
				},
			},
			finished: {
				type: Boolean,
				default () {
					return false;
				},
			},
		},
		data() {
			return {
				loading: false
			};
		},
		methods: {
			getMore() {
				this.$emit("getMore");
			},
			changeLoading() {
				this.loading = false;
			}
		},
		mounted() {

		},
	};
</script>

<style lang='scss' scoped>
	.content {
		width: 100%;
		background: #f4f4f4;
		padding-top: 0.24rem;
		box-sizing: border-box;

		.content-list {
			width: calc(100% - 0.6rem);
			/* height: 100rem; */
			margin: 0 auto;
		}
	}
</style>
