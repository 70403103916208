import { render, staticRenderFns } from "./SearchInfo.vue?vue&type=template&id=bfbf42b0&"
import script from "./SearchInfo.vue?vue&type=script&lang=js&"
export * from "./SearchInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports