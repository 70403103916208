<template>
	<li>
		<router-link
			:to="'/' + (goodsTab == 3?'ShopGoodsDetails':'details') + '?goods_id=' + goods.goods_id + '&types=1'"
			tag="div" class="img-box">
			<img :src="goods.cover_imgurl" alt class="img1" />
			<!-- <p class="tag" v-if="goods.sale_status == 1">开始日期：{{ goods.start_date }}</p>
            <p class="tag" v-if="goods.sale_status == 2">截止日期：{{ goods.end_date }}</p>-->
			<p class="tag flex" v-if="goods.sale_status == 1">
				距开始：
				<van-count-down :time="startTime" format="DD 天 HH : mm : ss" style="color:#fff;font-size: 12px;" />
			</p>
			<p class="tag flex" v-if="goods.sale_status == 2">
				距结束：
				<van-count-down :time="endTime" format="DD 天 HH : mm : ss" style="color:#fff;font-size: 12px;" />
			</p>
			<p class="out" v-if="goods.goods_stock == 0 || goods.sale_status == 0">
				<img src="@/assets/empty.png" alt />
			</p>
		</router-link>
		<router-link
			:to="'/' + (goodsTab == 3?'ShopGoodsDetails':'details') + '?goods_id=' + goods.goods_id + '&types=1'"
			tag="p" class="font1">{{ goods.goods_name }}</router-link>
		<!--
            判断显示头部标记
            如果 this.goods.sale_status == 1 显示 开始时间 start_date
            如果 this.goods.sale_status == 2 显示 截止时间 end_date
        -->
		<div class="list-wrap">
			<div :class="['list-price', { classify: listType == 'classify' }]">
				<p>
					<span>￥</span>
					{{ goods.sale_price * 1 }}
				</p>
				<s>￥{{ goods.market_price * 1 }}</s>
			</div>
			<div class="list-wrap-1">
				<!-- <p class="store">库存：{{ goods.goods_stock }}</p> -->
				<p class="store" v-if="goodsTab == 3">库存：{{ goods.goods_stock }}</p>
				<p class="store" v-else>销量：{{ goods.total_sale }}</p>
				<p class="p1" v-if="userInfo.user_types == 2">
					饭佣￥{{ userInfo.referrer_lv == 1?goods.commission2:goods.commission1 }}</p>
			</div>
			<div class="list-wrap-T-r">
				<p>{{ countVisit }}</p>
				<p>人气值</p>
			</div>
		</div>
		<div class="list-button">
			<router-link :to="'/' + (goodsTab == 3?'ShopMall':'classify') + '?id=' + goods.cate_id"
				v-if="listType != 'classify'">
				<p>
					进入{{ goods.cate_name }}
					<img src="@/assets/shouzhi.png" class="shouzhi" />
				</p>
			</router-link>
			<router-link
				:to="'/' + (goodsTab == 3?'ShopGoodsDetails':'details') + '?goods_id=' + goods.goods_id + '&types=1'">
				<p :class="{
            disable: btnText.status == '1',
            start: btnText.status == '2',
            out: btnText.status == '0',
          }">{{ btnText.text }}</p>
			</router-link>
		</div>
	</li>
</template>

<script>
	import {
		changeVisit
	} from "../../common/data";

	export default {
		props: {
			listType: {
				type: String,
				default () {
					return "";
				}
			},
			goodsTab: {
				type: Number,
				default () {
					return 0;
				}
			},
			goods: {
				type: Object,
				default () {}
			}
		},
		data() {
			return {
				userInfo: {},
				startTime: 0,
				endTime: 0
			};
		},

		mounted() {
			//  验证登录执行代码
			let goods = this.goods,
				nowTime = new Date().getTime(),
				startT = goods.start_time * 1000,
				endT = goods.end_time * 1000;
			this.startTime = startT - nowTime;
			this.endTime = endT - nowTime;
		},

		computed: {
			//  设置样式
			btnText() {
				let text = "立即抢购";
				let status = "2";
				if (this.goods.sale_status == 0) {
					text = "已结束";
					status = "0";
				} else if (this.goods.sale_status == 1) {
					text = "暂未开始";
					status = "1";
				} else if (this.goods.sale_status == 2) {
					if (this.goods.goods_stock > 0) {
						text = "立即抢购";
						status = "2";
					} else {
						text = "抢光了";
						status = "0";
					}
				} else {
					if (this.goods.goods_stock > 0) {
						text = "立即抢购";
						status = "2";
					} else {
						text = "抢光了";
						status = "0";
					}
				}
				return {
					status,
					text
				};
			},

			//  计算访问
			countVisit() {
				return changeVisit(this.goods.total_visit);
			},

			//  计算饭佣(已废弃)
			commission() {
				let referrer_lv = this.user_info.referrer_lv,
					rebate1 = Number(this.goods.rebate1),
					rebate2 = Number(this.goods.rebate2);
				if (referrer_lv == 1) {
					return rebate1.toFixed(2);
				}
				if (referrer_lv == 2) {
					return (rebate1 + rebate2).toFixed(2);
				}
			}
		}
	};
</script>

<style lang='scss' scoped>
	li {
		padding: 0.1rem;
		padding-bottom: 0.2rem;
		box-sizing: border-box;
		background: #ffffff;
		margin-bottom: 0.35rem;

		.img-box {
			position: relative;

			.tag {
				position: absolute;
				background-color: rgba($color: #000000, $alpha: 0.8);
				color: #ffffff;
				font-size: 0.22rem;
				width: auto;
				height: 0.52rem;
				line-height: 0.52rem;
				padding: 0 0.2rem;
				box-sizing: border-box;
				right: 0;
				top: 0;
			}

			.out {
				position: absolute;
				background-color: rgba($color: #000000, $alpha: 0.5);
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 2rem;
					height: 2rem;
				}
			}

		}

		.img1 {
			width: 100%;
			/*
    height: 3.9rem;
    */
			object-fit: cover;
		}

		.font1 {
			font-size: 0.31rem;
			margin-top: 0.2rem;
			color: #323233;
			margin-bottom: 0.26rem;
		}

		.list-wrap {
			// width: 100%;
			font-size: 0.22rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 1px solid #e9eaeb;
			padding-top: 0.33rem;
			padding-left: 0rem;
			box-sizing: border-box;
			color: #ff7320;

			.list-price {
				width: 100%;
				display: flex;
				align-items: baseline;

				p {
					color: #e50012;
					font-size: 0.6rem;

					span {
						font-size: 0.28rem;
					}
				}

				s {
					font-size: 0.26rem;
					color: #999999;
					margin-left: 0.15rem;
				}
			}

			.classify {
				p {
					font-size: 0.44rem;
				}

				s {
					font-size: 0.2rem;
				}
			}

			.list-wrap-1 {
				width: 3rem;
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				.store {
					color: #ff7320;
					font-size: 0.26rem;
					text-align: right;
					margin-bottom: 0.1rem;
				}

				.p1 {
					width: max-content;
					font-size: 0.24rem;
					padding: 0 0.1rem;
					height: 0.4rem;
					line-height: 0.4rem;
					border-radius: 0.2rem;
					background: #ff6300;
					color: #ffffff;
					text-align: center;
				}
			}

			.list-wrap-T-r {
				width: 2rem;
				text-align: center;
				font-size: 0.26rem;
				border-left: #e9eaeb solid 0.02rem;
				margin-left: 0.2rem;

				p:nth-child(1) {
					color: #ff7320;
					text-align: center;
					margin-bottom: 0.2rem;
				}

				p:nth-child(2) {
					color: #999999;
					text-align: center;
				}
			}
		}

		.list-button {
			width: 100%;
			padding: 0.3rem 0.2rem 0 0.17rem;
			box-sizing: border-box;
			color: #fff;
			font-size: 0.28rem;
			display: flex;
			justify-content: space-around;

			a {
				display: flex;
				align-items: center;
				justify-content: center;

				p {
					width: 2.77rem;
					height: 0.84rem;
					background: #6fbb46;
					text-align: center;
					border-radius: 0.5rem;
					line-height: 0.84rem;
				}

				.start {
					background: #e50012;
				}

				.disable {
					background: #b3b3b3;
				}

				.out {
					background: #e5e5e5;
				}
			}

			.shouzhi {
				height: 0.4rem;
				margin-left: 5px;
			}
		}
	}
</style>
