<template>
	<div>
		<!-- 商品列表 -->
		<goods-list :recommends="recommends" :finished="finished" :loading="loading" :isShow="isShow" @getMore="getMore"
			ref="goodsList"></goods-list>
	</div>
</template>

<script>
	import {
		SearchKeyWord
	} from "@/http/api";
	import login from "@/common/islogin";
	import GoodsList from "@/components/goods/GoodsList";

	export default {
		components: {
			GoodsList
		},
		data() {
			return {
				site_code: '',
				searchVal: '',
				isShow: false,
				isLoad: true,
				pages: 1,
				loading: false,
				finished: false,
				recommends: []
			};
		},
		mounted() {
			if (this.$route.query.key != '') {
				this.searchVal = this.$route.query.key
				this.site_code = this.$LStorage.getItem('curSiteInfo').site_code

				this.getGoodsList();
			} else {
				this.$dialog.alert({
                    message: '检索商品关键词为空,返回上一页看看',
                }).then(() => {
                    this.$router.go(-1);
                });
			}
		},
		beforeRouteEnter(to, from, next) {
			if (from.name == "Details") {
				to.meta.keepAlive = true;
			} else {
				to.meta.keepAlive = false;
			}
			// console.log(to)
			next();
		},
		methods: {
			// 点击搜索
			async getGoodsList() {
				if (this.isLoad) {
					const res = await SearchKeyWord({
						data: {
							pages: this.pages,
							site_code: this.site_code,
							goods_name: this.searchVal
						}
					});
					this.isShow = true;
					this.loading = false;
					this.$refs.goodsList.changeLoading(false);
					if (res.numPage == res.data.length) {
						this.finished = false;
					} else {
						this.isLoad = false;
						this.finished = true;
					}
					this.recommends = this.recommends.concat(res.data);
				}
			},
			getMore() {
				if (!this.finished) {
					this.pages++;
					this.getGoodsList();
				}
			}
		}
	};
</script>
